/*
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2021-10-15 15:01:48
 * @LastEditors: liuxia
 * @LastEditTime: 2022-04-07 17:10:11
 */
import Vue from 'vue';
import * as api from '@/api/projectDetail';
import { mapGetters } from 'vuex';
interface resData {
  [key: string]: any;
}
export default Vue.extend({
  data() {
    return {
      tableHeadHeight: 0,
      page: 1,
      limit: 100,
      tableData: [], // 表格数据
      constructSequenceNbr: '', // 工程项目sequenceNbr
      loading: false, // 数据渲染前加载
      scrollSwitch: false, // 是否重新请求数据
      scrollTop: 0, // 滑动距离
    };
  },
  props: {
    componentHeight: {
      type: Number,
      default: 0,
    },
    xmType: {
      type: String,
      default: '',
    },
    itemCategory: {
      type: String,
      default: '',
    },
    fbSequenceNbr: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['currentTreeInfo', 'projectSequenceNbr', 'tenderRelevancy', 'relevancy']),
    tableScroll() {
      const num: number = this.componentHeight - this.tableHeadHeight - 1;
      return { y: num, x: 'max-content' };
    },
    componentsStyle(): { height: string; wordBreak: string } {
      return {
        height: this.componentHeight + 'px',
        wordBreak: 'break-all',
      };
    },
  },
  watch: {
    // 'currentTreeInfo.sequenceNbr': function (val, oldVal) {
    //   console.log('val', val, oldVal)
    //   if (val === oldVal) return '';
    //   this.branchPage();
    // },
    fbSequenceNbr: function (val, oldVal) {
      console.log('fbSequenceNbr', val);
      if (val === oldVal) return '';
      this.page = 1;
      this.branchPage();
    },
  },
  created() {
    this.constructSequenceNbr =
      (this.$route.query.sequenceNbr as string) || this.projectSequenceNbr;
  },
  mounted() {
    this.setTableHeadHeight();
    if (!this.currentTreeInfo.ifChildrenMissing && this.xmType) {
      // setTimeout(() => {
      this.branchPage();
      // }, 500);
    }
    this.moreLoad();
  },
  methods: {
    moreLoad() {
      const tableEl = document.querySelector(
        '.invitation-item .ant-table-body'
      ) as HTMLDivElement;
      const that = this as Vue;
      tableEl.onscroll = () => {
        // 滚动条的高度 + 可视区域的高度 >= 滚动里面的总高度时，就说明滚动到底部了
        if (
          Math.ceil(tableEl.scrollTop + tableEl.clientHeight + 4) >=
          tableEl.scrollHeight
        ) {
          if (this.scrollSwitch) {
            this.scrollTop = tableEl.scrollTop;
            this.page++;
            this.branchPage();
          }
          // if (res.result.total > 1000 && this.limit === res.result.list.length) {
          //   this.page++;
          //   this.branchPage();
          // }
        }
      };
    },
    setTableHeadHeight() {
      setTimeout(() => {
        const tableEl = document.querySelector(
          '.ant-table-fixed'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      }, 500);
    },
    branchPage() {
      this.loading = true;
      const apiData = {
        page: this.page,
        limit: this.limit,
        xmType: this.xmType,
        itemCategory: this.itemCategory,
        fbSequenceNbr: this.fbSequenceNbr,
        unitSequenceNbr: this.currentTreeInfo.sequenceNbr,
        isNew: true,
        fromtype: '02',
        constructSequenceNbr: this.constructSequenceNbr,
        singleSequenceNbr: this.currentTreeInfo.parentId,
      };
      api
        .coccMtdsResource_1_project_branchsCategory(this.xmType, apiData)
        .then((res: resData) => {
          if (res.code) {
            if (this.page === 1) {
              (
                document.querySelector(
                  '.invitation-item .ant-table-body'
                ) as HTMLDivElement
              ).scrollTop = 0;
              this.tableData = [];
            }
            if (res.result.list.length === this.limit) {
              this.scrollSwitch = true;
            } else {
              this.scrollSwitch = false;
            }
            this.tableData = this.tableData.concat(res.result.list);
            if (this.page !== 1) {
              this.$nextTick(() => {
                (
                  document.querySelector(
                    '.invitation-item .ant-table-body'
                  ) as HTMLDivElement
                ).scrollTop = this.scrollTop;
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
